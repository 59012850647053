
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import Header from '@/modules/landingPage/components/layout/Header.vue';
import Footer from '@/modules/landingPage/components/layout/Footer.vue';
import FooterEU from '@/modules/landingPage/components/eu/layout/Footer.vue';
import BlogPostCard from '@/modules/landingPage/components/BlogPostCard.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* INTERFACES */
/* STORE */
import { blogHttpRepository } from '@/modules/landingPage/repositories/blog.httpRepository'
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { isoDateToDDMMYYYY } from '@/utils/date-functions'

@Component({
  components: {
      Header,
      Footer,
      FooterEU,
      BlogPostCard,
      SvgIcon,
      RichTextRenderer
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: this.canonicalUrl,
        },
      ],
      meta: [
        {
          name: 'description',
          content: this.description,
        },
      ],
    };
  },
})
export default class Blog extends Vue {

    blogPost = {items: [], total: 0, includes: {Asset: [], Entry: []}}
    loading = true;

    
    async mounted(){
        if (this.$route.params.id){
            await this.fetchBlogPostById();
        }
        else this.$router.push({name: 'NotFound'})
        this.loading = false;
    }

    get canonicalUrl() {
      const currentRoute = this.$route;
      return `${window.location.origin}${currentRoute.path}]`;
    }

    get description(){
      try{
        return `Bithonor: `+ this.blogPost.items[0].fields.title
      }catch(e){
        return 'Bithonor'
      }
    }


    async fetchBlogPostById(noPagination?: boolean){
        this.blogPost = await blogHttpRepository.getPostById(this.$route.params.id);
        this.blogPost.items.map(item => {
            item.fields.category = item.fields.category.map(cat =>{
                cat = this.entries.find(entry => entry.id === cat.sys.id).name
                return cat
            })
            return item
        })
    }

    get image(){
        try{
            if (this.blogPost.items && Object.keys(this.assets).length > 0){
                return this.assets[this.blogPost.items[0].fields.image.sys.id].fields.file.url
            }
            return './assets/landingPage/blog.jpg'
        } catch(e: any){
            return './assets/landingPage/blog.jpg'
        }
    }

    get assets(){
        const newAssets = {}
        this.blogPost.includes.Asset.forEach(item => {
            const sysId = item.sys.id;
            newAssets[sysId] = item;
        });
        return newAssets
    }

    get entries(){
        return this.blogPost.includes.Entry.map(entry => {
            return {id: entry.sys.id, name: entry.fields.name}
        })
    }

    renderMarksClasses(marks: Array<{type: string}>){
        return marks.map(mark => {
            if (mark.type === 'italic') return 'fst-italic'
            if (mark.type === 'bold') return 'fw-bold'
            if (mark.type === 'underline') return 'underline'
            if (mark.type === 'superscript') return 'superscript'
            if (mark.type === 'subscript') return 'subscript'
            // if (mark.type === 'code') return 'code'
        }).join('')
    }

    renderRichText(node, parentClass?) {
      if (!node || !node.content) return '';
      return node.content
        .map((childNode) => {
          if (childNode.nodeType === 'text') {
              if (childNode.marks.length === 0)  return childNode.value;
              else if (childNode.marks.find(mark => mark.type === 'code')) return `<code class="fs-standart ${this.renderMarksClasses(childNode.marks)}">${childNode.value}</code>`;
              else return `<span class="${parentClass ? parentClass : 'fs-standart'} ${this.renderMarksClasses(childNode.marks)}">${childNode.value}</span>`;
          }

          if (childNode.nodeType === 'hyperlink') {
            if (childNode.data.uri.includes('https://www.youtube.com/embed'))
                return `<div class="w-100 flex justify-content-center">
                            <iframe class="max-w-100 mx-auto" width="560" height="315" src="${childNode.data.uri}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>`
            return `<a href="${childNode.data.uri}" target="_blank">${childNode.content[0].value}</a>`
          }

          if (childNode.nodeType === 'embedded-asset-block') {
            return `<div class="flex flex-column  max-w-80 min-w-60 w-sm-100 mx-auto">
                        <img class="w-100" src="${this.assets[childNode.data.target.sys.id].fields.file.url}" alt="${this.assets[childNode.data.target.sys.id].fields.title}" title="${this.assets[childNode.data.target.sys.id].fields.title}" />
                        <p class="w-100 fs-small mt-2 text-center">${this.assets[childNode.data.target.sys.id].fields.description}</p>
                    </div>`;
          }

          if (childNode.nodeType === 'paragraph') {
            return `<p class="fs-standart">${this.renderRichText(childNode)}</p>`;
          }

          if (childNode.nodeType.includes('heading-')) {
            return `<h${childNode.nodeType[8]} class="my-4">${this.renderRichText(childNode, `heading-${childNode.nodeType[8]}`)}</h${childNode.nodeType[8]}>`;
          }

          if (childNode.nodeType  === 'blockquote'){
            return `<div class="blockquote">${this.renderRichText(childNode)}</div>`
          }

          if (childNode.nodeType === 'unordered-list'){
            return `<ul>${this.renderRichText(childNode)}</ul>`
          }
          if (childNode.nodeType === 'ordered-list'){
            return `<ol>${this.renderRichText(childNode)}</ol>`
          }
          if (childNode.nodeType === 'list-item'){
            return `<li>${this.renderRichText(childNode)}</li>`
          }
          // Add more conditions to handle other node types as needed

          return '';
        })
        .join('');
    }

    get formattedDate(){
        return isoDateToDDMMYYYY(this.blogPost.items[0].sys.createdAt)
    }

    get europeVersion(){
        return process.env.VUE_APP_EUROPE_VERSION
    }

}
